<template>
    <page-content>

        <common-table
                ref="table"
                path="device-online"
                search-placeholder="搜索设备MAC"
                :columns="columns">

            <template slot="search">
<!--                <a-button ghost type="primary" @click="add">新增</a-button>-->
            </template>

            <template slot="online" slot-scope="{record}">
                <a-tag v-if="record.online" color="green">上线</a-tag>
                <a-tag v-else color="cyan">离线</a-tag>
            </template>

            <template slot="type" slot-scope="{record}">
                <a-tag v-if="record.type==1" color="red">连接中断</a-tag>
                <a-tag v-if="record.type==2" color="orange">上报超时</a-tag>
                <a-tag v-if="record.type==3" color="green">服务器重启</a-tag>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-view @click="view(record)"></action-view>
            </template>

        </common-table>


    </page-content>
</template>
<script>

    export default {
        components: {
        },
        data() {
            return {}
        },

        computed: {
            columns() {
                return [
                    {
                        title: '设备名称',
                        dataIndex: 'nickname',
                    },
                    {
                        title: '设备地址',
                        dataIndex: 'address',
                    },
                    {
                        title: 'MAC',
                        dataIndex: 'mac',
                    },
                    {
                        title: 'IP',
                        dataIndex: 'ip'
                    },
                    {
                        title: '上下线',
                        dataIndex: 'online',
                        scopedSlots: {customRender: 'online'},
                        filters: [
                            {text: '全部', value: ''},
                            {text: '上线', value: true},
                            {text: '离线', value: false}
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '离线类型',
                        dataIndex: 'type',
                        scopedSlots: {customRender: 'type'},
                        filters: [
                            {text: '全部', value: ''},
                            {text: '连接中断', value: '1'},
                            {text: '上报超时', value: '2'},
                            {text: '服务器重启', value: '3'}
                        ],
                        filterMultiple: false,
                    },
                    {
                        title: '时间',
                        dataIndex: 'createdAt'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 60,
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add(){
                this.$refs.cameraEdit.show()
            },
            view(record) {
                this.$router.push(`/device/info/${record.iotId}`)
            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
